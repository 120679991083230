
    import Component from 'vue-class-component'
    import { Mixins, Watch } from 'vue-property-decorator'
    import TableMixin from '@/mixins/TableMixin.vue'
    import { stringUtils } from '@/utils/string'
    import moment from 'moment'
    // import { Http } from '@/apis/Http'
    // import { Pagination } from '@/typings/CommonTypings'
    import { SearchLeasObjectApi } from '@/apis/SearchLeasObjectApi'

    @Component({
        name: 'SearchLeasObjectHistoryOfRequestsSuperviser',
        components: {
        }
    })

    export default class HistoryOfRequestsSuperviser extends Mixins(TableMixin) {
        @Watch('pagination.currentPage')
        onPaginationChange(val) {
            this.getRequestList(val, this.perPage)
        }

        @Watch('search')
        onSearchChange() {
            this.getRequestList(1, this.perPage)
        }

        @Watch('sortBy')
        onSortByChange() {
            this.getRequestList(1, this.perPage)
        }

        sortBy = []
        sortDesc = []
        simpleTable = true
        perPage = 10

        requestsList = {
            headers: [
                {
                    text: 'Номер',
                    sortable: false,
                    align: 'start',
                    value: 'id'
                },
                {
                    text: 'Дата запроса',
                    sortable: false,
                    align: 'start',
                    value: 'date'
                },
                {
                    text: 'Транспортное средство',
                    sortable: false,
                    align: 'start',
                    value: 'car'
                },
                {
                    text: 'КП',
                    sortable: false,
                    align: 'start',
                    value: 'countCommerceProposal'
                },
                {
                    text: '',
                    sortable: false,
                    value: 'options'
                }
            ],
            items: []
        }

        pagination = {
            total: null,
            currentPage: null,
            firstPage: null,
            lastPage: null,
            firstPageUrl: null,
            lastPageUrl: null,
            nextPageUrl: null,
            perPage: null
        }

        created() {
          // @ts-ignore
            this.$setLoading(true)
            this.getRequestList(1, this.perPage)
        }

        getRequestList(page, perPage) {
            SearchLeasObjectApi.getRequestList(page, perPage, this.search, this.sortBy[0], this.sortDesc[0])
                .then(response => {
                    this.requestsList.items = Object.values(response.data.data)
                    this.pagination = this.fillPagination(response.data)
                })
                .catch(() => {
                    // this.showErrorSystemNotification('Что-то пошло не так! Попробуйте позже')
                })
                .finally(() => {
                  // @ts-ignore
                    this.$setLoading(false)
                })
        }

        mounted() {
            if (this.$route.params?.sended) {
              // @ts-ignore
                this.showSuccessSystemNotification('Запрос успешно отправлен')
            }
        }

        get search() {
            return this.$store.state.common.searchLcRequests
        }

        set search(item) {
            this.$store.state.common.searchLcRequests = item
        }

        fillPagination(data) {
            return {
                total: data.total,
                currentPage: data.current_page,
                firstPage: data.first_page ?? null,
                lastPage: data.last_page ?? null,
                firstPageUrl: data.first_page_url ?? null,
                lastPageUrl: data.last_page_url ?? null,
                nextPageUrl: data.next_page_url ?? null,
                perPage: data.per_page ?? 10
            }
        }

        formatDate(date: Date): string {
            return moment(date).format('LLL')
        }

        countOrders(number: number): string {
            return stringUtils.pluralForm(number, ['запрос', 'запроса', 'запросов'])
        }
    }
